import axios from 'axios';
import { config } from '../constants';

var domainService = config.url.domainApi;

const foreignRequest = (type, fullPath,params,config) => axios
    .request({ url: `${fullPath}`, method: type, params:params, headers:config})
    .then(req => req.data);

export const getFiles = params => foreignRequest('get', domainService+'getAll.php',params,{headers:{'Content-Type':'application/json'}});
export const getDates = params => foreignRequest('get', domainService+'getDates.php',params,{headers:{'Content-Type':'application/json'}});
export const getDirs = params => foreignRequest('get', domainService+'getDirs.php',params,{headers:{'Content-Type':'application/json'}});

