
const prod = {
 url: {
    domainApi: 'cam1/',
    baseUrlPic: 'cam1/'
 }
};

const dev = {
 url: {
  domainApi: 'https://brandsen.qrits.com.ar/cam1/',
  baseUrlPic: 'https://brandsen.qrits.com.ar/cam1/'
 }
};
export const config = process.env.NODE_ENV === 'development' ? dev : prod;