import React from 'react';
import {getDates, getDirs, getFiles} from "./api/api";
import {FormGroup, Table,Card} from "react-bootstrap";
import NavBarPage from "./NavBarPage";
import { config } from './constants';




export default class RootPanel extends  React.Component {
  constructor(props) {
    super(props);
    this.state = {
        today : new Date(),
        fecha:'',
        dates:[],
        dirs:[],
        rows:[],
        aDir:'',
        selectedImage:null,
        interval:null,
        baseUrlPic :config.url.baseUrlPic,
        

    };
  }
  

    startAutoUpdate=()=> {
        this.stopAutoUpdate();
        this.setState({interval : setInterval(() => this.update(), 5000)});
    }

    stopAutoUpdate=()=> {
        clearInterval(this.state.interval);
        this.setState({interval : null});
    }

  fechaActual(){
      if (this.state.dates[0]) {
        return this.state.dates[0].date.toString();

      }else{
            return this.state.today.getFullYear() + '-' + (("0" + (this.state.today.getMonth() + 1)).slice(-2)) + '-' + ("0" + this.state.today.getDate()).slice(-2);
      }


  }

  getLastPicture(){
      this.handleClickRow(this.state.rows[0].url);

  }

  update=()=>{

      getFiles({"fecha": this.fechaActual(),"dir":this.state.aDir })
          .then((files) => {
                  this.setState({rows: files.elements.reverse()});
                  this.getLastPicture();
              }
          )
          .catch(() => this.setState({ error: 'Algo anduvo mal! Volvé a internar' }));

  }
  
   
   

  componentDidMount() {
      this.setState({ fecha: this.fechaActual()});



      this.getDirectories();
      this.update();


  }

  getDatesOnDir(dir){

      console.log("DIR = "+dir);
      getDates({"dir":dir})
          .then((allDates) => {
              this.setState({dates:allDates,fecha:allDates[0].date});
              this.update();

              }
          )
          .catch(() => this.setState({ error: 'Algo anduvo mal! Volvé a internar' }));

  }

    handleChangeFecha= event => {
        this.setState({ fecha: event.target.value});
        getFiles({"fecha": event.target.value,"dir":this.state.aDir})
            .then((files) => this.setState({rows:files.elements.reverse()}))
            .catch(() => this.setState({ error: 'Algo anduvo mal! Volvé a internar' }));

    }

    handleChangeDir = event=> {
        this.setState({ aDir: event.target.value});
        this.getDatesOnDir(event.target.value);
    }

    getDirectories(){
        getDirs({})
            .then((allItems) => {
                this.setState({dirs:allItems,aDir:allItems[0].value});
                console.log(this.state.aDir);
                this.getDatesOnDir(allItems[0].value);
                }
            )
            .catch(() => this.setState({ error: 'Algo anduvo mal! Volvé a internar' }));



    }


    handleClickRow = value =>{
        let root=this.state.baseUrlPic;
        this.setState({selectedImage:root + value})
    }





  render () {



const ImageAndLoader=()=>{
    console.log(this.state.selectedImage);
    if (this.state.selectedImage){
        return <img class="img-fluid" alt="Imagen registrada" name="showPoint" id={"showPoint"} className="rounded" src={this.state.selectedImage}/>
    }else{
        return (<div class="card p-5 ">
                    <div class="text-center">
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden">&nbsp;</span>
                        </div>
                        <p class="m-2"> Aguarde un instante...</p>
                    </div>
                    </div>);
        
    }   
}

    return (
        <div>
            <NavBarPage />

            <div className=" col-12 d-inline-block ">

            
              
                <div className=" container ms-2 p-0 mb-2 mb-sm-2  me-4 col col-12 col-sm-8 col-md-8 col-xl-8 img sticky-top float-left "  >
                    <ImageAndLoader />
                </div>

                <div className="container mt-0  col col-12 col-sm-4 col-md-4 col-xl-4 float-end d-inline-block " >
                    <div class="card ">
                        <Card.Body>

                        <div className="row">
                            <div className="col col-6 col-sm-12 col-md-6 col-xl-4">
                                <FormGroup controlId="formBasicPlatform">
                                    <select name="dir" className={"form-control browser-default custom-select"}
                                            value={this.state.aDir} onChange={this.handleChangeDir}>
                                        {this.state.dirs.map(each=> (
                                            <option value={each.value}>{each.name}</option>
                                        ))};
                                    </select>
                                </FormGroup>
                            </div>
                        </div>
                    <div className="row">
                        <div className="col col-6 col-sm-12 col-md-6 col-xl-5">
                            <FormGroup controlId="formBasicPlatform">
                                <select name="fecha" className={"form-control browser-default custom-select"}
                                        value={this.state.fecha} onChange={this.handleChangeFecha}>
                                    {this.state.dates.map(each=> (
                                        <option value={each.date}>{each.date}</option>
                                    ))};
                                </select>
                            </FormGroup>
                        </div>
                        <div className="col col-2 col-sm-4 col-md-2 col-xl-2 ">
                            <button type="button" className="btn btn-primary" onClick={this.update}>
                                <i className="fas fa-sync-alt" onClick={this.update}></i>
                            </button>
                        </div>
                        <div className="col col-2 col-sm-4 col-md-2 col-xl-2 ">
                            <button type="button" className="btn btn-outline-success" onClick={this.startAutoUpdate}>
                                <i className="fas fa-play" onClick={this.startAutoUpdate}></i>
                            </button>
                        </div>
                        <div className="col col-2 col-sm-4 col-md-2 col-xl-2 " >
                            <button type="button" className="btn btn-outline-danger" onClick={this.stopAutoUpdate}>
                                <i className="fas fa-stop" onClick={this.stopAutoUpdate}></i>
                            </button>

                        </div>

                    </div>
                    <div className="row">

                        <div className="container-fluid table-scroll-vertical">
                            <Table striped condensed hover >
                                <thead>
                                <tr>
                                    <th>Orden</th>
                                    <th>Archivo</th>
                                </tr>
                                </thead>
                                <tbody >
                                {this.state.rows.map((file) => (

                                    <tr onClick={() => this.handleClickRow(file.url)}>
                                        <td><a onClick={() => this.handleClickRow(file.url)}> {file.key}</a></td>
                                        <td><a onClick={() => this.handleClickRow(file.url)}> {file.value}</a></td>
                                    </tr>


                                ))}

                                </tbody>
                            </Table>


                        </div>
                    </div>
                    </Card.Body></div>
                </div>
                
            </div>
        </div>

    )
  }


}

