import React, { Component} from "react";

import {Navbar} from 'react-bootstrap';

import '../node_modules/bootstrap-css-only/css/bootstrap.css';
import './NavBar.css';




class NavBarPage extends Component {
  state = {

  };

render() {

  return (


  <Navbar  collapseOnSelect expand="md" bg="light" variant="light" className="navbar-light sticky-top heavy-rain-gradient darken-3 mb-4 justify-content-center">
      Remote Shots v2.0
  </Navbar>


    );
  }
}

export default (NavBarPage);